import { Box } from '@iheartradio/web.accomplice/box';
import { Flex } from '@iheartradio/web.accomplice/flex';
import {
  SelectField,
  SelectOption,
} from '@iheartradio/web.accomplice/select-field';
import { Text } from '@iheartradio/web.accomplice/text';
import { Playback } from '@iheartradio/web.playback';
import { isString, slugify } from '@iheartradio/web.utilities';
import { useNavigation, useSearchParams } from '@remix-run/react';
import { type Key, useCallback, useState } from 'react';
import { isNullish } from 'remeda';

import { useItemSelected } from '~app/analytics/use-item-selected';
import {
  CardCarousel,
  useCarouselSlidesContext,
} from '~app/components/card-carousel';
import { ContentCardImage } from '~app/components/content-card/content-card';
import { RankedContentCard } from '~app/components/content-card/ranked-content-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import {
  SelectInteractionProvider,
  useSelectInteraction,
} from '~app/contexts/select-interaction';
import { useUser } from '~app/contexts/user';
import { Play } from '~app/playback/controls/play';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';
import { isPremiumUser } from '~app/utilities/user';

import type { HomeLoaderData } from '../_home';

export type TopPlaylists = HomeLoaderData['topPlaylists'];

export type PlaylisMoods = HomeLoaderData['playlistMoods'];

export type PlaylistGenres = HomeLoaderData['playlistGenres'];

export type TopPlaylist = Exclude<TopPlaylists, undefined>[number];

type PlaylistGenreSelectProps = {
  genres: PlaylistGenres;
  currentPlaylistGenre: string;
  stateKey?: string;
};

type PlaylistMoodSelectProps = {
  moods: PlaylisMoods;
  currentPlaylistMood: string;
  stateKey?: string;
};

const sectionTitle = 'Top Playlists';

const BY_PLAYLIST_GENRE_STATE_KEY = 'byPlaylistGenre';

const BY_PLAYLIST_MOOD_STATE_KEY = 'byPlaylistMood';

const selectFieldcss = {
  width: 'auto',
  maxWidth: {
    xsmall: '10.6rem',
    small: '12rem',
    medium: '18rem',
  },
};

export function PlaylistGenreSelect(props: PlaylistGenreSelectProps) {
  const { genres, currentPlaylistGenre, stateKey } = props;
  const [_searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useSelectInteraction();

  const onSelectionChange = useCallback(
    (key: Key) => {
      if (isString(key) && key !== currentPlaylistGenre?.toString()) {
        setSearchParams(
          prev => {
            prev.set('playlistGenre', key.toString());
            return prev;
          },
          {
            replace: true,
            preventScrollReset: true,
            state: { stateKey },
          },
        );
      }
    },
    [currentPlaylistGenre, setSearchParams, stateKey],
  );

  return (
    <SelectField
      aria-label="playlist genres select field"
      css={selectFieldcss}
      data-test="playlist-genres"
      defaultSelectedKey={currentPlaylistGenre}
      isOpen={isOpen}
      items={[
        { key: '0', label: 'All Genres', value: '0' },
        ...Object.entries(genres).map(([genreId, genre]) => {
          return {
            key: genreId.toString(),
            label: genre.title,
            value: genreId,
          };
        }),
      ]}
      key={`GenreSelect-${currentPlaylistGenre?.toString()}`}
      name="playlistGenres"
      onBlur={() => setIsOpen(false)}
      onOpenChange={(isOpen: boolean) => {
        setIsOpen(isOpen);
      }}
      onSelectionChange={onSelectionChange}
    >
      {item => (
        <SelectOption key={item.key} textValue={item.label}>
          {item.label}
        </SelectOption>
      )}
    </SelectField>
  );
}

export function PlaylistMoodSelect(props: PlaylistMoodSelectProps) {
  const { moods, currentPlaylistMood } = props;
  const [_searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);

  const onSelectionChange = useCallback(
    (key: Key) => {
      if (isString(key) && key !== currentPlaylistMood.toString()) {
        setSearchParams(
          params => {
            params.set('mood', key.toString());
            return params;
          },
          { preventScrollReset: true },
        );
      }
    },
    [currentPlaylistMood, setSearchParams],
  );

  return (
    <SelectField
      aria-label="playlist-moods"
      css={selectFieldcss}
      data-test="playlist-moods"
      defaultSelectedKey={currentPlaylistMood}
      isOpen={isOpen}
      items={[
        { key: '0', label: 'All Moods', value: '0' },
        ...Object.entries(moods ?? []).map(([_moodId, mood]) => {
          const moodId = mood?.title?.replace(/ +/g, '')?.toLowerCase();
          return {
            key: moodId?.toString() ?? '',
            label: mood?.title ?? '',
            value: moodId ?? '',
          };
        }),
      ]}
      key={`mood -${currentPlaylistMood?.toString()}`}
      name="playlistGenres"
      onBlur={() => setIsOpen(false)}
      onOpenChange={(isOpen: boolean) => {
        setIsOpen(isOpen);
      }}
      onSelectionChange={onSelectionChange}
    >
      {item => {
        return (
          <SelectOption key={item.key} textValue={item.label}>
            {item.label}
          </SelectOption>
        );
      }}
    </SelectField>
  );
}

function Slide({
  loadingStrategy = 'eager',
  pageName,
  playlist,
  index,
  sectionPosition,
}: {
  loadingStrategy?: HTMLImageElement['loading'];
  pageName: string;
  playlist: TopPlaylist;
  index: number;
  sectionPosition: number;
}) {
  const { onItemSelected } = useItemSelected();
  const isMobile = useIsMobile();
  const user = useUser();
  const isPremium = isPremiumUser(user);

  const type =
    isPremium ?
      Playback.StationType.Playlist
    : Playback.StationType.PlaylistRadio;

  const { playing } = Play.usePlaylistPlay({
    context: 0,
    type,
    id: `${Number(playlist?.resource?.publishedUserId)}::${playlist?.resource?.publishedPlaylistId}`,
  });

  const podcastUrl = buildPlaylistUrl({
    name: playlist?.resource?.title ?? '',
    userId: playlist?.resource?.publishedUserId ?? '',
    id: playlist?.resource?.publishedPlaylistId ?? '',
  });

  return (
    <CardCarousel.Slide key={playlist?.id}>
      <RankedContentCard
        href={podcastUrl}
        image={
          <ContentCardImage
            alt={playlist?.resource?.title ?? ''}
            decoding="auto"
            loading={loadingStrategy}
            src={playlist?.resource?.urlImage || undefined}
            width={isMobile ? 75 : 150}
          />
        }
        imageButton={
          <Play.Playlist
            context={0}
            id={`${Number(playlist?.resource?.publishedUserId)}::${playlist?.resource?.publishedPlaylistId}`}
            shuffle={false}
            size={48}
            type={type}
          />
        }
        isActive={playing}
        linesForTitle={2}
        onNavigate={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${Playback.StationType.Podcast}|${playlist?.id}`,
                name: playlist?.resource?.title ?? '',
                type: Playback.StationType.Playlist,
              },
            },
          });
        }}
        rank={index + 1}
        title={playlist?.resource?.title ?? ''}
      />
    </CardCarousel.Slide>
  );
}

export const TopPlaylistsCarousel = ({
  pageName,
  playlists,
  playlistGenres,
  playlistMoods,
  currentPlaylistGenre,
  currentMood,
  sectionPosition,
}: {
  pageName: string;
  playlists: TopPlaylists;
  playlistGenres: HomeLoaderData['playlistGenres'];
  playlistMoods: HomeLoaderData['playlistMoods'];
  currentPlaylistGenre: HomeLoaderData['currentPlaylistGenre'];
  currentMood: HomeLoaderData['selectedMood'];
  sectionPosition: number;
}) => {
  const navigation = useNavigation();
  const loadedGenre = `mood=${currentMood}`;

  const [initialSlides] = useCarouselSlidesContext();

  return (
    <SelectInteractionProvider>
      <CardCarousel
        isEmpty={playlists?.length === 0 ? true : false}
        key={`${currentPlaylistGenre ?? 'all_genres'}_${currentMood ?? 'all_moods'}`}
        kind="ranker"
        loading={
          navigation.state === 'loading' &&
          navigation.location.search.includes('mood') &&
          !navigation.location.search.includes(loadedGenre)
        }
        title={
          <Flex
            alignItems="center"
            gap="$8"
            justifyContent={{ mobile: 'space-between', large: 'flex-start' }}
            paddingRight={{ small: '$8', touch: '$0' }}
            width="100%"
          >
            {sectionTitle ?
              <Text as="h3" kind={{ mobile: 'h4', large: 'h3' }}>
                {sectionTitle}
              </Text>
            : null}
            <Flex gap="$4">
              <PlaylistMoodSelect
                currentPlaylistMood={currentMood ?? '0'}
                moods={playlistMoods}
                stateKey={BY_PLAYLIST_MOOD_STATE_KEY}
              />
              <PlaylistGenreSelect
                currentPlaylistGenre={currentPlaylistGenre ?? '0'}
                genres={playlistGenres}
                stateKey={BY_PLAYLIST_GENRE_STATE_KEY}
              />
            </Flex>
          </Flex>
        }
      >
        {playlists && playlists.length > 0 ?
          playlists.map((playlist, index) =>
            !isNullish(playlist) ?
              <Slide
                index={index}
                key={playlist.id}
                loadingStrategy={index < initialSlides ? 'eager' : 'lazy'}
                pageName={pageName}
                playlist={playlist}
                sectionPosition={sectionPosition}
              />
            : <Box key={index}></Box>,
          )
        : null}
      </CardCarousel>
    </SelectInteractionProvider>
  );
};
