import { SelectOption } from '@iheartradio/web.accomplice/select-field';
import { MediaServerURL } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { isString, slugify } from '@iheartradio/web.utilities';
import { useNavigation, useSearchParams } from '@remix-run/react';
import { type Key, useCallback, useState } from 'react';

import { useItemSelected } from '~app/analytics/use-item-selected';
import {
  CardCarousel,
  useCarouselSlidesContext,
} from '~app/components/card-carousel';
import { CarouselSelect } from '~app/components/carousel-select/carousel-select';
import { ContentCardImage } from '~app/components/content-card/content-card';
import { RankedContentCard } from '~app/components/content-card/ranked-content-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import { SelectInteractionProvider } from '~app/contexts/select-interaction';
import { Play } from '~app/playback/controls/play';
import {
  AnalyticsContext,
  DEFAULT_POPULAR_GENRES,
} from '~app/utilities/constants';
import { buildArtistUrl } from '~app/utilities/urls';

import type { HomeLoaderData } from '../_home';

export type TopArtists = HomeLoaderData['topArtists'];
export type TopArtist = Exclude<
  HomeLoaderData['topArtists'],
  undefined
>[number];
export type ArtistGenres = HomeLoaderData['genres'];
export type CurrentGenre = HomeLoaderData['currentGenre'];

type ArtistGenreSelectProps = {
  genres: ArtistGenres;
  selectedGenreKey: string;
  stateKey?: string;
};

const sectionTitle = 'Top Artist Radio';

const BY_ARTIST_GENRE_STATE_KEY = 'byArtistGenre';

export function ArtistGenresSelect(props: ArtistGenreSelectProps) {
  const { genres, selectedGenreKey, stateKey } = props;
  const [_searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);

  const onSelectionChange = useCallback(
    (key: Key) => {
      if (isString(key) && key !== selectedGenreKey.toString()) {
        setSearchParams(
          params => {
            params.set('genre', key.toString());
            return params;
          },
          {
            replace: true,
            preventScrollReset: true,
            state: { stateKey },
          },
        );
      }
    },
    [selectedGenreKey, setSearchParams, stateKey],
  );

  return (
    <CarouselSelect
      aria-label="artist-genres"
      data-test="artist-genres"
      defaultSelectedKey={selectedGenreKey}
      isOpen={isOpen}
      items={[
        {
          key: DEFAULT_POPULAR_GENRES.toString(),
          label: 'All Genres',
          value: DEFAULT_POPULAR_GENRES.toString(),
        },
        ...Object.entries(genres).map(([_id, value]) => {
          return {
            key: value.id.toString(),
            label: value.genreName,
            value: value.id.toString(),
          };
        }),
      ]}
      key={selectedGenreKey.toString()}
      name="artistgenres"
      onBlur={() => setIsOpen(false)}
      onOpenChange={(isOpen: boolean) => {
        setIsOpen(isOpen);
      }}
      onSelectionChange={onSelectionChange}
      sectionTitle={sectionTitle}
    >
      {item => (
        <SelectOption key={item.key} textValue={item.label}>
          {item.label}
        </SelectOption>
      )}
    </CarouselSelect>
  );
}

function Slide({
  artist,
  index,
  loadingStrategy = 'eager',
  pageName,
  sectionPosition,
}: {
  artist: TopArtist;
  index: number;
  loadingStrategy?: HTMLImageElement['loading'];
  pageName: string;
  sectionPosition: number;
}) {
  const { onItemSelected } = useItemSelected();
  const isMobile = useIsMobile();

  const { playing } = Play.useArtistPlay({
    id: Number(artist?.contentId),
    context: 0,
  });

  if (!artist) {
    return null;
  }

  const artistUrl = buildArtistUrl({
    id: Number(artist.contentId),
    name: artist.label ?? '',
  });

  return artist.contentId ?
      <CardCarousel.Slide key={`topArtists_${artist?.contentId}`}>
        <RankedContentCard
          description="& similar artists"
          href={artistUrl}
          image={
            <ContentCardImage
              alt={artist?.label ?? ''}
              decoding={index === 0 ? 'sync' : 'auto'}
              {...(index === 0 ? { fetchpriority: 'high' } : {})}
              loading={loadingStrategy}
              src={MediaServerURL.fromCatalog({
                type: 'artist',
                id: artist.contentId,
              })
                .quality(isMobile ? 40 : 50)
                .run('circle')}
              width={isMobile ? 70 : 140}
            />
          }
          imageButton={
            <Play.Artist context={0} id={Number(artist?.contentId)} size={48} />
          }
          isActive={playing}
          linesForTitle={2}
          onNavigate={() => {
            onItemSelected({
              pageName,
              section: slugify(sectionTitle),
              context: AnalyticsContext.Carousel,
              itemPosition: index,
              sectionPosition,
              assets: {
                asset: {
                  id: `${Playback.StationType.Artist}|${artist?.contentId}`,
                  name: artist?.label ?? '',
                },
              },
            });
          }}
          previewShape="circle"
          rank={index + 1}
          title={artist?.label ?? ''}
        />
      </CardCarousel.Slide>
    : null;
}

function TopArtistsCarouselSlides({
  artists,
  pageName,
  sectionPosition,
}: {
  artists: TopArtists;
  pageName: string;
  sectionPosition: number;
}) {
  const [initialSlides] = useCarouselSlidesContext();

  return artists.length > 0 ?
      artists.map((artist, index) => (
        <Slide
          artist={artist}
          index={index}
          key={artist.contentId}
          loadingStrategy={index < initialSlides ? 'eager' : 'lazy'}
          pageName={pageName}
          sectionPosition={sectionPosition}
        />
      ))
    : null;
}

export function TopArtistsCarousel({
  pageName,
  artists,
  artistGenres,
  currentGenre,
  sectionPosition,
}: {
  pageName: string;
  artists: TopArtists;
  artistGenres: ArtistGenres;
  currentGenre: CurrentGenre;
  sectionPosition: number;
}) {
  const navigation = useNavigation();
  const loadedGenre = `genre=${currentGenre?.id}`;

  return (
    <SelectInteractionProvider>
      <CardCarousel
        isEmpty={artists.length === 0 ? true : false}
        key={currentGenre?.id ?? 0}
        kind="ranker"
        loading={
          navigation.state === 'loading' &&
          navigation.location.search.includes('genre') &&
          !navigation.location.search.includes(loadedGenre)
        }
        title={
          <ArtistGenresSelect
            genres={artistGenres}
            selectedGenreKey={
              currentGenre?.id?.toString() ?? DEFAULT_POPULAR_GENRES.toString()
            }
            stateKey={BY_ARTIST_GENRE_STATE_KEY}
          />
        }
      >
        <TopArtistsCarouselSlides
          artists={artists}
          pageName={pageName}
          sectionPosition={sectionPosition}
        />
      </CardCarousel>
    </SelectInteractionProvider>
  );
}
