import { SelectOption } from '@iheartradio/web.accomplice/select-field';
import { MediaServerURL } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { isString, slugify } from '@iheartradio/web.utilities';
import { useNavigation, useSearchParams } from '@remix-run/react';
import { type Key, useCallback, useMemo } from 'react';
import { isNullish } from 'remeda';

import { useItemSelected } from '~app/analytics/use-item-selected';
import {
  CardCarousel,
  useCarouselSlidesContext,
} from '~app/components/card-carousel';
import { CarouselSelect } from '~app/components/carousel-select/carousel-select';
import { ContentCardImage } from '~app/components/content-card/content-card';
import { RankedContentCard } from '~app/components/content-card/ranked-content-card';
import { useIsMobile } from '~app/contexts/is-mobile';
import {
  SelectInteractionProvider,
  useSelectInteraction,
} from '~app/contexts/select-interaction';
import { Play } from '~app/playback/controls/play';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPodcastUrl } from '~app/utilities/urls';

import type { HomeServerLoaderData } from '../.server/loader';

export type TopPodcasts = Awaited<HomeServerLoaderData['topPodcasts']>;

export type TopPodcast = Awaited<
  Exclude<HomeServerLoaderData['topPodcasts'], null>[number]
>;

export type PodcastsCategories = Awaited<
  HomeServerLoaderData['podcastCategories']
>;

export type CurrentCategory = Awaited<HomeServerLoaderData['currentCategory']>;

type PodcastCategorySelectProps = {
  categories: PodcastsCategories;
  selectedCategoryKey: string;
  stateKey: string;
};

const sectionTitle = 'Top Podcasts';

const BY_CATEGORIES_STATE_KEY = 'byCategory';

export function PodcastCategorySelect(props: PodcastCategorySelectProps) {
  const { categories, selectedCategoryKey, stateKey } = props;
  const [_searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useSelectInteraction();

  const onSelectionChange = useCallback(
    (key: Key) => {
      if (isString(key) && key !== selectedCategoryKey.toString()) {
        setSearchParams(
          params => {
            params.set('category', key.toString());
            return params;
          },
          {
            replace: true,
            preventScrollReset: true,
            state: { stateKey },
          },
        );
      }
    },
    [selectedCategoryKey, setSearchParams, stateKey],
  );

  return (
    <CarouselSelect
      aria-label="podcast-categories"
      data-test="podcast-categories"
      defaultSelectedKey={selectedCategoryKey}
      isOpen={isOpen}
      items={[
        { key: '0', label: 'All Categories', value: '0' },
        ...Object.entries(categories).map(([_id, value]) => {
          return {
            key: value.id.toString(),
            label: value.name,
            value: value.name,
          };
        }),
      ]}
      key={selectedCategoryKey.toString()}
      name="podcastcategories"
      onBlur={() => setIsOpen(false)}
      onOpenChange={(isOpen: boolean) => {
        setIsOpen(isOpen);
      }}
      onSelectionChange={onSelectionChange}
      sectionTitle={sectionTitle}
    >
      {item => (
        <SelectOption key={item.key} textValue={item.label}>
          {item.label}
        </SelectOption>
      )}
    </CarouselSelect>
  );
}

function Slide({
  loadingStrategy = 'eager',
  pageName,
  podcast,
  index,
  sectionPosition,
}: {
  loadingStrategy?: HTMLImageElement['loading'];
  pageName: string;
  podcast: TopPodcast;
  index: number;
  sectionPosition: number;
}) {
  const { onItemSelected } = useItemSelected();
  const isMobile = useIsMobile();

  const { playing } = Play.usePodcastPlay({
    id: Number(podcast?.id),
    context: 0,
  });

  const podcastUrl = buildPodcastUrl({
    podcastId: Number(podcast?.id),
    slug: podcast && 'slug' in podcast ? podcast.slug : '',
  });

  return podcast.id ?
      <CardCarousel.Slide key={podcast?.id}>
        <RankedContentCard
          href={podcastUrl}
          image={
            <ContentCardImage
              alt={podcast?.title ?? ''}
              decoding={index === 0 ? 'sync' : 'auto'}
              {...(index === 0 ? { fetchpriority: 'high' } : {})}
              loading={loadingStrategy}
              src={MediaServerURL.fromCatalog({
                type: 'podcast',
                id: podcast.id,
              }).quality(isMobile ? 40 : 50)}
              width={isMobile ? 70 : 140}
            />
          }
          imageButton={
            <Play.Podcast context={0} id={Number(podcast?.id)} size={48} />
          }
          isActive={playing}
          linesForTitle={2}
          onNavigate={() => {
            onItemSelected({
              pageName,
              section: slugify(sectionTitle),
              context: AnalyticsContext.Carousel,
              itemPosition: index,
              sectionPosition,
              assets: {
                asset: {
                  id: `${Playback.StationType.Podcast}|${podcast?.id}`,
                  name: podcast?.title ?? '',
                  type: Playback.StationType.Podcast,
                },
              },
            });
          }}
          rank={index + 1}
          title={podcast?.title ?? ''}
        />
      </CardCarousel.Slide>
    : null;
}

const TopPodcastsCarouselSlides = ({
  pageName,
  podcasts,
  sectionPosition,
}: {
  pageName: string;
  podcasts: TopPodcasts;
  sectionPosition: number;
}) => {
  const [initialSlides] = useCarouselSlidesContext();

  const slides = useMemo(
    () => podcasts.filter(podcast => !isNullish(podcast)),
    [podcasts],
  );

  return slides.length > 0 ?
      podcasts.map((podcast, index) =>
        !isNullish(podcast) ?
          <Slide
            index={index}
            key={podcast.id}
            loadingStrategy={index < initialSlides ? 'eager' : 'lazy'}
            pageName={pageName}
            podcast={podcast}
            sectionPosition={sectionPosition}
          />
        : null,
      )
    : null;
};

export const TopPodcastsCarousel = ({
  pageName,
  podcasts,
  podcastCategories,
  currentCategory,
  sectionPosition,
}: {
  pageName: string;
  podcasts: TopPodcasts;
  podcastCategories: PodcastsCategories;
  currentCategory: CurrentCategory;
  sectionPosition: number;
}) => {
  const navigation = useNavigation();
  const loadedCategory = `category=${currentCategory?.id}`;

  return (
    <SelectInteractionProvider>
      <CardCarousel
        isEmpty={podcasts.length === 0 ? true : false}
        key={currentCategory?.id ?? 0}
        kind="ranker"
        loading={
          navigation.state === 'loading' &&
          navigation.location.search.includes('category') &&
          !navigation.location.search.includes(loadedCategory)
        }
        title={
          <PodcastCategorySelect
            categories={podcastCategories}
            selectedCategoryKey={currentCategory?.id?.toString() ?? '0'}
            stateKey={BY_CATEGORIES_STATE_KEY}
          />
        }
      >
        <TopPodcastsCarouselSlides
          pageName={pageName}
          podcasts={podcasts}
          sectionPosition={sectionPosition}
        />
      </CardCarousel>
    </SelectInteractionProvider>
  );
};
